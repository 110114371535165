
#home {
	padding-top: 120px; /* HEADER OFFSET */

	h1 {
		letter-spacing: .1rem;
	}

	.font-blue {
		color: $malibu;
	}

	.wrapper {
		padding: 5rem .5rem;

		@media (min-width: map-get($grid-breakpoints, sm)) {
			padding: 10rem 2rem;
		}

		@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
			h3 {
				&.heading {
					@include font-size($h3-font-size * 0.8);
				}
			}
		}
	}

	.phrase {
		line-height: 1.5;
	}

	section.wrapper {
		counter-increment: section 1;

		.heading {
			&:before {
				content: "0" counter(section) ".";
			}
		}
	}

	.heading {
		display: flex;
		position: relative;
		white-space: nowrap;
		align-items: center;

		&:before {
			margin-right: 7px;
			font-family: $font-sfmono;
			font-weight: normal;
			color: $malibu;
			position: relative;
			font-size: 75%;
			top: 0;

			@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
				font-size: 70%;
			}
		}

		&:not(.no-hr) {
			&:after {
				content: "";
				display: block;
				position: relative;
				height: 1px;
				width: 300px;
				background-color: $pickled-bluewood;
				margin-left: 30px;
				top: 0;
			}
		}
	}

	.avatar {
			display: flex;
			border-radius: 3px;

			img {
				filter: grayscale(100%) contrast(1);
				transition: filter 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
			}

			&:hover {
				img {
					filter: none;
				}
			}
	}

	.big {
		font-size: 260%;
	}

	#about-me {
		.techs {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(0, 125px));
			justify-content: center;
			list-style: none;
			padding: 0;
      gap: 1em;
      font-size: .9em;
      max-width: 1100px;
      margin: 0 auto;

			@include media-breakpoint-up(sm) {
				grid-template-columns: repeat(auto-fit, minmax(0, 143px));
			}

			@include media-breakpoint-up(lg) {
				grid-template-columns: repeat(auto-fit, minmax(0, 150px));
			}

			li {
				position: relative;
				padding-left: 1.3em;
        margin-bottom: .5em;

				&:before {
					font-family: $font-sfmono;
					content: "▹";
					position: absolute;
					left: 5px;
					color: $malibu;
				}
			}
		}
	}
}