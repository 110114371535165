/*
    Imports
*/
@font-face {
  font-family: "Calibre"; 
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Calibre'),
    url('/static/fonts/Calibre/Calibre.woff2') format('woff2'), 
    url('/static/fonts/Calibre/Calibre.woff') format('woff');
};
@font-face {
  font-family: "SF Mono"; 
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('SF Mono'),
    url('/static/fonts/SFMono/SFMono-Regular.woff2') format('woff2'), 
    url('/static/fonts/SFMono/SFMono-Regular.woff') format('woff');
};

$font-sfmono: "SF Mono", Arial, monospace, sans-serif;
$font-calibre: "Calibre", Arial, sans-serif;