#header {
  background-color: $blue-zodiac;
  box-shadow: rgba(2, 12, 27, 0.8) 0px 10px 30px -10px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  pointer-events: auto;
  user-select: auto;
  top: 0;
  transform: translateY(0);
  will-change: transform;

  &.hidden {
    transform: translateY(-100px);
  }

  .logo {
    max-width: 42px;

    @media (max-width: map-get($grid-breakpoints, sm) - 1px) {
      max-width: 36px;
    }
  }

  ol.nav {
    font-size: 1.1rem;

    li {
      display: flex;
      position: relative;
      white-space: nowrap;
      align-items: center;
      margin: 0 10px;
      counter-increment: section 1;

      &:not(.no-counter):before {
        content: "0" counter(section) ".";
        margin-right: 7px;
        font-family: $font-sfmono;
        font-weight: normal;
        color: $malibu;
        position: relative;
        font-size: 75%;
        top: 0;

        @media (max-width: map-get($grid-breakpoints, sm) - 1px) {
          font-size: 70%;
        }
      }

      .link {
        color: $moon-raker;
      }

    }
  }

  .burger {
    position: relative;
    z-index: 101;
    padding: 5px 10px 0;

    .navbar-toggle {
      background-color: transparent;
      outline: 0 none;
      position: relative;
      border-radius: 0;
      border: 0 none;
      display: block;
      width: 24px;
      padding: 4px;
      margin-top: 6px;

      .icon-bar {
        background-color: $malibu;
        display: block;
        height: 2px;
        opacity: 1;
        position: absolute;
        right: 0;
        will-change: 'opacity, transform, width, top';

        &:nth-child(1) {
          width: 100%;
          top: 0;
          transition: top 0.1s ease-out 0s, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
        }

        &:nth-child(2) {
          width: 80%;
          top: 7px;
          transition: opacity 0.1s ease-out 0.12s;
        }

        &:nth-child(3) {
          width: 60%;
          top: 14px;
          transition: top 0.1s ease-out 0s, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
        }
      }

      &.expanded {
        .icon-bar {
          width: 100%;

          &:nth-child(1) {
            transform: rotate(225deg);
            top: 7px;
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: rotate(-225deg);
            top: 7px;
          }
        }
      }
    }
  }

  .flying-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    transform: translateX(100vw);
    visibility: hidden;
    outline: 0 none;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

    &.show {
      transform: translateX(0vw);
      visibility: visible;
      outline: 0 none;
    }

    .menu {
      position: relative;
      width: 210px;
      height: 100vh;
      margin-left: auto;
      right: 0;
      background-color: rgb(23, 42, 69);

      ol.nav {
        font-size: 90%;
        width: 100%;

        > li {
          flex-direction: column;
          margin-bottom: 20px;

          &:before {
            content: "0" counter(section) ".";
            font-size: 90%;
            margin-right: 0;
          }
        }
      }

    }
  }
}