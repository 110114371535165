#footer {
  color: $moon-raker;

  a {
    transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    display: block;
    color: $moon-raker;
    will-change: transform;

    &:hover {
      text-decoration: none;
      transform: translateY(-5px);
      color: $malibu;
    }
  }

  ul {
    &.list-inline {
      > li {
        a {
          padding: 0 10px;
        }
      }
    }
  }

  .bottom-nav {
    .left, .right {
      position: fixed;
      bottom: 0;

      &:after {
        content: "";
        display: block;
        width: 1px;
        height: 70px;
        background-color: $moon-raker;
        margin: 0px auto;
      }
    }

    .left {
      left: 12px;
      font-size: 80%;

      a {
        padding: 10px;
      }
    }

    .right {
      right: 12px;
      line-height: 36px; // Just to equalize it with .left's width

      -webkit-writing-mode: vertical-rl;
      writing-mode: vertical-rl;
      margin: 20px auto;
      padding: 10px;
      font-size: 14px;
      letter-spacing: 0.3em;
    }
  }
}