.project-comp {
  .project {
    margin-bottom: 100px;
  }

  h4, h5 {
    color: $malibu;
  }

  .title {
    font-weight: 700;
    font-size: 120%;
  }

  .description {
    background-color: $pickled-bluewood;
    color: $blue-haze;
    font-size: 90%;
    border-radius: 3px;

    @media (max-width: map-get($grid-breakpoints, sm) - 1px) {
      @include background-opacity($pickled-bluewood, 0.7);
    }

    > p:last-child {
      margin-bottom: 0;
    }
  }

  .techs {
    font-size: 70%;
    color: $blue-haze;

    &.list-inline {
      > li {
        display: inline-block;

        &:not(:first-child) {
          margin-left: 1.6rem;
        }
      }

    }
  }

  .links {
    color: $blue-haze;
  }

  .project-link {
    position: relative;
    will-change: background-color;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 3;
      background-color: rgba(10, 25, 47, 0.1);
      transition: background-color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    }

    .img-thumbnail {
      filter: grayscale(100%) contrast(1) brightness(90%);
      border-radius: 3px;
      will-change: 'filter';
    }

    &:hover {
      &:before {
        background-color: transparent;
      }

      .img-thumbnail {
        filter: none;
      }
    }
  }

  .project-deets {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      background-image: none !important;
    }

    > .overlay {
      position: absolute;
      z-index: 1;
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(10, 25, 47, 0.9);
    }

    .content {
      position: relative;
      z-index: 2;
    }
  }

  .links {
    .link {
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
}