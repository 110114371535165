@import 'fonts'; 
@import 'variables';
@import "bootstrap";

// Font awesome
// $fa-font-path: "~font-awesome/fonts";
@import "~font-awesome/scss/font-awesome";


html, body {
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-calibre;
  font-size: 1.25rem;
  line-height: $line-height-base;

  @media (max-width: map-get($grid-breakpoints, sm) - 1px) {
    font-size: 1.25rem * 0.8;
  }
}

body {
  background-color: $blue-zodiac;
  color: $bali-hai;
}

h1, h2, h3, h4, h5, h6 {
  color: $blue-haze;
}

hr {
  border-color: $bali-hai;
}

.font-sfmono {
  font-family: $font-sfmono;
}

.ninja {
  color: transparent;
  background-color: transparent;
}

.hide-native-scrollbar {
  scrollbar-width: none; 
  /* Firefox 64 */
  -ms-overflow-style: none; /* Internet Explorer 11 */
  &::-webkit-scrollbar { /** WebKit */
      display: none;
  }
}

/**
* Default display
*/
.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

/**
* Mixins utilities
*/
@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}

